import React, { useEffect, useState, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import {
  RootGetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
} from '../context/userContext'
import {
  getMenu,
  updateMenu,
  publishMenu,
  getBuildMenuStatus,
  getIndicator,
  renderTooltip,
} from '../helpers'
import { ntfError, ntfSuccess, ntfInfo, ntfWarn, ntfErrorWithLink } from '../helpers/notifications'
import { useIsDesktop } from '../helpers/hooks'

const ExportBlock = () => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const {
    menuId,
    alias,
    isThereUnsavedChanges,
    defaultLanguage,
    events,
    isDeleted,
    logo,
    mainBgColor,
    mainTextColor,
    splashBgColor,
    splashTextColor,
    socials,
  } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setEvents, setIsPreviewOpen } = useContext(
    ConstructorSetterContext
  )
  const { user } = useAuth0()
  const { t, i18n } = useTranslation()
  const isDesktop = useIsDesktop()

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [statusData, setStatusData] = useState(null)

  let timeout = null

  const autoUpdate = async () => {
    timeout && clearTimeout(timeout)
    if (getBuildMenuStatus(events) === 1) {
      setIsPublishing(true)
      const menuData = await getMenu(token, user.sub, menuId)
      if (menuData.success && menuData.data && menuData.data.events) {
        const updatedEvents = menuData.data.events
        setStatusData(getIndicator(updatedEvents, i18n.language, t))
        const status = getBuildMenuStatus(updatedEvents)
        switch (status) {
          case 0: {
            setIsPublishing(false)
            ntfInfo({ message: t('MESSAGE_MENU_PUBLISH_SUCCESS') })
            break
          }
          case 2: {
            setIsPublishing(false)
            ntfError({ message: t('MESSAGE_MENU_PUBLISH_ERROR') })
            break
          }
          default:
            break
        }
        timeout = setTimeout(async () => {
          setEvents(updatedEvents)
        }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
      } else {
        ntfErrorWithLink({
          title: t('MESSAGE_ERROR_TITLE'),
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
        timeout = setTimeout(async () => {
          autoUpdate()
        }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
      }
    } else {
      setIsPublishing(false)
      setStatusData(getIndicator(events, i18n.language, t))
      timeout = setTimeout(async () => {
        autoUpdate()
      }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
    }
  }

  useEffect(() => {
    autoUpdate()
    return () => timeout && clearTimeout(timeout)
  }, [events, i18n.language])

  useEffect(() => {
    setIsPreviewOpen(isDesktop)
  }, [isDesktop, setIsPreviewOpen])

  useEffect(() => {
    setIsDataLoaded(
      menuLocals &&
        logo !== null &&
        mainBgColor &&
        mainTextColor &&
        splashBgColor &&
        splashTextColor &&
        socials
    )
  }, [menuLocals, logo, mainBgColor, mainTextColor, splashBgColor, splashTextColor, socials])

  const handlePreview = () => {
    setIsPreviewOpen((state) => !state)
  }

  const handleSave = async (e) => {
    if (isLoadingGlobal || isSaving) return false
    if (!isThereUnsavedChanges) return true
    setIsSaving(true)
    try {
      const menuToSave = {
        id: menuId,
        alias,
        categoriesList: 'main',
        defaultLanguage,
        events,
        isDeleted,
        logo: logo ? logo : '', // fix: replace null/undefined with empty string
        mainBgColor,
        mainTextColor,
        splashBgColor,
        splashTextColor,
        socials,
        content: menuLocals,
        url: '#',
      }

      const savedMenu = await updateMenu(token, user.sub, menuToSave)
      if (savedMenu.success) {
        setEvents(savedMenu.data)
        setIsThereUnsavedChanges(false)
        ntfSuccess({ message: t('MESSAGE_MENU_SAVE_SUCCESS') })
        return true
      } else {
        ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
        console.error('save menu error:\n', savedMenu.error)
        return false
      }
    } catch (e) {
      ntfErrorWithLink({
        title: 'save menu error',
        message: e.message,
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
      console.error('save menu error:\n', e.message)
      return false
    } finally {
      setIsSaving(false)
    }
  }

  const handlePublish = async (e) => {
    if (!handleSave(e)) return false

    if (isLoadingGlobal || isSaving) return false
    try {
      const publishedMenu = await publishMenu(token, user.sub, menuId, alias)
      if (publishedMenu.success) {
        setIsPublishing(true)
        ntfInfo({ message: t('MESSAGE_MENU_PUBLISH_STARTED') })
        events.buildedAt = new Date().toISOString() //needed to start the process of checking the build status
        return true
      } else {
        if (publishedMenu.error === 2) {
          ntfWarn({ message: t('MESSAGE_MENU_PUBLISH_IN_PROGRESS') })
        } else {
          ntfError({ message: t('MESSAGE_MENU_PUBLISH_ERROR') })
        }
        return false
      }
    } catch (e) {
      setIsPublishing(false)
      ntfErrorWithLink({
        title: 'publish menu error',
        message: e.message,
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
      console.error('publish menu error:\n', e.message)
      return false
    }
  }

  return (
    <>
      <div id='export-import' className='export-import'>
        {!isDesktop && (
          <div className='item preview'>
            {isDataLoaded ? (
              <div
                className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                onClick={handlePreview}
              >
                {t('CON_BUTTON_PREVIEW')}
              </div>
            ) : (
              <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
                <Skeleton />
              </div>
            )}
          </div>
        )}
        <div className='item save'>
          {isDataLoaded ? (
            <div
              className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
              onClick={(e) => handleSave(e)}
            >
              {isSaving && (
                <Spinner as='span' role='status' animation='border' variant='danger' size='sm' />
              )}{' '}
              {t('CON_BUTTON_SAVE')}
            </div>
          ) : (
            <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
              <Skeleton />
            </div>
          )}
        </div>
        <div className='item publish'>
          {isDataLoaded ? (
            <>
              {isDesktop ? (
                <OverlayTrigger
                  placement='bottom-end'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    statusData && (
                      <div className='status'>
                        {statusData.map((item, i) => (
                          <p key={i}>{item}</p>
                        ))}
                      </div>
                    )
                  )}
                >
                  <div
                    className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                    onClick={(e) => handlePublish(e)}
                  >
                    {isPublishing && (
                      <Spinner
                        as='span'
                        role='status'
                        animation='border'
                        variant='danger'
                        size='sm'
                      />
                    )}{' '}
                    {t('CON_BUTTON_PUBLISH')}
                  </div>
                </OverlayTrigger>
              ) : (
                <div
                  className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                  onClick={(e) => handlePublish(e)}
                >
                  {isPublishing && <Spinner animation='border' variant='outline-info' size='sm' />}{' '}
                  {t('CON_BUTTON_PUBLISH')}
                </div>
              )}
            </>
          ) : (
            <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
              <Skeleton />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ExportBlock
