import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { SubcatDragWrapper } from './DragWrapper'
import { findTargetById, renderTooltip, openizator } from '../helpers'
import { defaultSubcategory } from '../data/index'
import UnitNameDebouncedInput from './UnitNameDebouncedInput'
import SubCategory from './SubCategory'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop, useDragDown, useDragUp } from '../helpers/hooks'

const { IconAddItem, IconRemoveItem, IconEyeVisible, IconEyeHidden, IconDragOld } = icons

const Category = ({ catId, catIndex }) => {
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage, showList } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setShowList } = useContext(ConstructorSetterContext)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [defaultLangData, setDefaultLangData] = useState(null)
  const [langData, setLangData] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (menuLocals && defaultLanguage) {
      const _defaultLangData = menuLocals.find((langObj) => langObj.lang === defaultLanguage)
      setDefaultLangData(_defaultLangData)
    }
  }, [menuLocals, defaultLanguage])

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      const _langData = menuLocals.find((langObj) => langObj.lang === currentLanguage)
      setLangData(_langData)
    }
  }, [menuLocals, currentLanguage])

  const target = useRef(null)

  const dragDownBtn = useDragDown([catIndex], catId)
  const dragUpBtn = useDragUp([catIndex], catId)

  useEffect(() => {
    if (showList) {
      showList.forEach((item) => {
        if (item.name === catId) {
          setIsOpen(item.isOpen)
        }
      })
    }
  }, [showList, catId])

  const getSubcatList = () => (
    <ul className='subcategories'>
      {langData.subItems[catIndex]?.subItems?.map((item, n) => {
        return <SubCategory key={n} subcatId={item.id} catIndex={catIndex} subcatIndex={n} />
      })}
    </ul>
  )

  const handleToggleVisibility = () => {
    const defaultIsVisible = findTargetById(defaultLangData, catId).isVisible
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, catId)
        currentUnit.isVisible = !defaultIsVisible
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, catId)
          currentUnit.isVisible = !currentUnit.isVisible
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteCategory = () => {
    menuLocals.forEach((local) => {
      local.subItems = local.subItems.filter((item) => item.id !== catId)
    })
    setMenuLocals(menuLocals)
    setIsThereUnsavedChanges(true)
    return true
  }

  const handleCreateSubcategory = () => {
    const _defaultSubcategory = defaultSubcategory()
    const _menuLocals = JSON.parse(JSON.stringify(menuLocals))

    _menuLocals.map((local) => {
      for (let category of local.subItems) {
        if (category.id === catId) {
          category.subItems.push({ ..._defaultSubcategory })
          break
        }
      }
      return local
    })

    showList.push({ name: _defaultSubcategory.id, isOpen: true })
    setShowList(showList)
    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const getHideButton = () => {
    const buttonItself = (
      <label className='btn-hide input-label' htmlFor={`${currentLanguage}_${catId}`}>
        {langData.subItems[catIndex].isVisible ? (
          <IconEyeVisible color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        ) : (
          <IconEyeHidden color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        )}
        <input
          type='checkbox'
          id={`${currentLanguage}_${catId}`}
          name='isVisible'
          value={langData.subItems[catIndex].isVisible}
          onClick={
            isLocalsLoading
              ? null
              : (e) => {
                  handleToggleVisibility(e)
                }
          }
        />
      </label>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_HIDE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDeleteButton = () => {
    const buttonItself = (
      <div
        className='btn-delete'
        onClick={
          isLocalsLoading
            ? null
            : () => {
                setShowRemoveModal({
                  show: true,
                  name: langData.subItems[catIndex].catName,
                  kind: t('CON_MODAL_DELETE_CATEGORY'),
                  cbName: handleDeleteCategory,
                  cbArgs: [],
                })
              }
        }
      >
        <IconRemoveItem color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DELETE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDefaultLanguageButtons = () =>
    currentLanguage === defaultLanguage && (
      <Col className='default-language-buttons'>
        {isDesktop ? (
          <>
            <div className='toggleHide'>{getHideButton()}</div>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DRAG'))}
              target={target.current}
            >
              <span className='drag'>
                <IconDragOld color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='30px' />
              </span>
            </OverlayTrigger>
          </>
        ) : (
          <>
            {getDeleteButton()}
            {getHideButton()}
            {dragUpBtn}
          </>
        )}
      </Col>
    )

  const getHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex].catName
    return (
      <>
        {showHint ? (
          <div className='hint-row'>
            <InputGroup size='sm' className='hint-content'>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_CONTENT_CATEGORY_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <div className='hint-container'>{defaultLangData.subItems[catIndex].catName}</div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const getNameAndButton = () => (
    <div className='name-and-delete'>
      {getHint()}
      {!isDesktop && getDefaultLanguageButtons()}

      <div className='actions-block'>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('CON_CONTENT_CATEGORY_TEXT')}</InputGroup.Text>
          </InputGroup.Prepend>
          <UnitNameDebouncedInput
            incomingKind='cat'
            incomingName='catName'
            incomingValue={langData.subItems[catIndex].catName}
            incomingId={catId}
            incomingIndex={catIndex}
          />
        </InputGroup>
        {currentLanguage === defaultLanguage && (isDesktop ? getDeleteButton() : dragDownBtn)}
        {currentLanguage !== defaultLanguage && getHideButton()}
      </div>
    </div>
  )

  const getHeader = () => (
    <Row
      aria-expanded={isOpen}
      className={`unit-header expandable${isOpen ? ' open' : ''}${
        currentLanguage !== defaultLanguage ? ' translated' : ''
      }`}
    >
      <div className='expand-btn' onClick={() => openizator(catId, showList, setShowList)} />
      {getNameAndButton()}
      {isDesktop && getDefaultLanguageButtons()}
    </Row>
  )

  const getContent = () => (
    <Collapse in={isOpen}>
      <Container className='unit-content' fluid>
        <SubcatDragWrapper cat={catIndex}>{getSubcatList()}</SubcatDragWrapper>

        {currentLanguage === defaultLanguage && (
          <Row className='control-box'>
            <div
              className='v2-button sm'
              onClick={() => {
                handleCreateSubcategory()
              }}
            >
              <IconAddItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
              {t('CON_CONTENT_SUBCATEGORY_TEXT')}
            </div>
          </Row>
        )}
      </Container>
    </Collapse>
  )

  return langData ? (
    <Container
      className={`category-container ${langData.subItems[catIndex].isVisible ? '' : 'hidden'}`}
      as='section'
      id={catId}
      fluid
    >
      {getHeader()}
      {getContent()}
    </Container>
  ) : null
}

export default Category
