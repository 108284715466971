import React, { useState, useMemo, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'react-bootstrap'
import { format, compareDesc, parseISO } from 'date-fns'
import { RootGetterContext, RootSetterContext } from '../../context/userContext'
import { getSummary } from '../../helpers/index'
import { ntfError } from '../../helpers/notifications'

const { REACT_APP_BASE_URL, REACT_APP_JWT_AUDIENCE, REACT_APP_AUTH0_SCOPE } = process.env

const SummaryPage = ({ history }) => {
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const { setToken, setIsLoadingGlobal } = useContext(RootSetterContext)

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()

  const [summary, setSummary] = useState(null)
  const [checkBoxState, setCheckBoxState] = useState({ online: true, deleted: true, notpub: true })

  // useEffect(() => {
  //   if (summary) {
  //     console.log('summary :>> ', summary)
  //     summary.forEach((user) => {
  //       console.log('user.createdAt :>> ', format(parseISO(user.createdAt), 'dd MMM yyyy HH:mm'))
  //     })
  //   }
  // }, [summary])

  const isUserCanUseSummary = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${REACT_APP_BASE_URL}/roles`]
      return userRolesArray.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  const menuCounter = useMemo(() => {
    let _menuOnlineCounter = 0
    let _menuDeletedCounter = 0
    let _menuNotPubCounter = 0
    if (summary) {
      summary.forEach((user) => {
        user.menuList.forEach((menu) => {
          if (menu.isDeleted) {
            _menuDeletedCounter += 1
          } else {
            menu.isPublished ? (_menuOnlineCounter += 1) : (_menuNotPubCounter += 1)
          }
        })
      })
    }
    return { online: _menuOnlineCounter, deleted: _menuDeletedCounter, notpub: _menuNotPubCounter }
  }, [summary])

  useEffect(() => {
    if (!isUserCanUseSummary) {
      history.push('/')
    }
  }, [user])

  const updateSummary = async () => {
    setIsLoadingGlobal(true)
    try {
      const _summary = await getSummary(token, user.sub)
      if (_summary.success) {
        _summary.data.sort((first, second) =>
          compareDesc(parseISO(first.createdAt), parseISO(second.createdAt))
        )
        setSummary(_summary.data)
      } else {
        ntfError({ message: 'Summary update suddenly got error' })
        console.error('Summary update suddenly got error:\n', _summary.error)
      }
    } catch (e) {
      ntfError({ message: 'Summary update suddenly got error' })
      console.error('Summary update suddenly got error:\n', e.message)
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  const getMenuItem = (index, name, link, isOnline = false) => (
    <div className='menu-item' key={index}>
      <a
        className='alias-link'
        href={isOnline ? `https://${link}.gimme.menu` : null}
        target='_blank'
        rel='noopener noreferrer'
      >
        {`https://${link}.gimme.menu/`}
      </a>
      {name && <p className='menu-name'>{name}</p>}
    </div>
  )

  const getToggler = (name, index = 0) => (
    <div className='toggler' key={index}>
      <p>{`${name === 'notpub' ? 'not pub' : name}: `}</p>
      <div className='switch'>
        <input
          id={`switch-${name}`}
          type='checkbox'
          checked={checkBoxState[name]}
          onChange={() => {
            checkBoxState[name] = !checkBoxState[name]
            setCheckBoxState({ ...checkBoxState })
          }}
          className='switch-input'
        />
        <label htmlFor={`switch-${name}`} className='switch-label'>
          {name}
        </label>
      </div>
    </div>
  )

  useEffect(() => {
    ;(async () => {
      setIsLoadingGlobal(true)
      try {
        if (!token) {
          const accessToken = await getAccessTokenSilently({
            audience: REACT_APP_JWT_AUDIENCE,
            scope: REACT_APP_AUTH0_SCOPE,
          })
          setToken(accessToken)
        } else {
          await updateSummary()
        }
      } catch (e) {
        ntfError({ message: e.message })
        history.push('/')
        console.error('getMenu Error:\n', e.message)
      } finally {
        setIsLoadingGlobal(false)
      }
    })()
    // eslint-disable-next-line
  }, [token])

  return (
    <main className='summary-page-container'>
      <div className='inner'>
        {!summary && (
          <>
            <h1>Summary</h1>
            <Spinner animation='border' variant='outline-info' size='lg' />
          </>
        )}

        {summary && (
          <>
            <div className='title'>
              <h1>Summary</h1>
              <div className='refresh-button' onClick={isLoadingGlobal ? null : updateSummary}>
                {isLoadingGlobal ? (
                  <Spinner animation='border' variant='outline-info' size='sm' />
                ) : (
                  'refresh'
                )}
              </div>
            </div>
            <div className='stats-and-togglers'>
              <div className='togglers'>
                {['online', 'deleted', 'notpub'].map((prop, p) => getToggler(prop, p))}
              </div>
              <div className='stats'>
                <p>{`Users: ${summary.length}`}</p>
                <p>{`Menus: ${menuCounter.online + menuCounter.deleted + menuCounter.notpub}`}</p>
                <p>{`Online: ${menuCounter.online}`}</p>
                <p>{`Not pub: ${menuCounter.notpub}`}</p>
                <p>{`Deleted: ${menuCounter.deleted}`}</p>
              </div>
            </div>
          </>
        )}

        {summary && (
          <>
            <div className='summary-list'>
              {summary.map((user, u) => {
                let _deleted = []
                let _online = []
                let _notPublished = []
                user.menuList.forEach((menu) => {
                  if (menu.isDeleted) {
                    _deleted.push(menu)
                  } else if (!menu.isPublished) {
                    _notPublished.push(menu)
                  } else _online.push(menu)
                })
                return (
                  <article key={u} className='user-item'>
                    {user.name && <p className='user-info name'>{user.name}</p>}
                    {user.email && <p className='user-info name'>{user.email}</p>}
                    {!user.email && !user.name && <p className='user-info id'>{user.id}</p>}
                    <p className='user-info created-at'>
                      Created at: {format(parseISO(user.createdAt), 'dd MMM yyyy HH:mm')}
                    </p>
                    {user.menuList && (
                      <div className='menu-list'>
                        {_online.length && checkBoxState.online ? (
                          <>
                            <h4 className='subtitle-online'>online</h4>
                            {_online.map((menu, m) => getMenuItem(m, menu.name, menu.alias, true))}
                          </>
                        ) : null}
                        {_notPublished.length && checkBoxState.notpub ? (
                          <>
                            <h4 className='subtitle-not-published'>not published</h4>
                            {_notPublished.map((menu, m) => getMenuItem(m, menu.name, menu.alias))}
                          </>
                        ) : null}
                        {_deleted.length && checkBoxState.deleted ? (
                          <>
                            <h4 className='subtitle-deleted'>deleted</h4>
                            {_deleted.map((menu, m) => getMenuItem(m, menu.name, menu.alias))}
                          </>
                        ) : null}
                      </div>
                    )}
                  </article>
                )
              })}
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default withRouter(SummaryPage)
