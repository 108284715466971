const smthWrong = { success: false, error: 'something went extremely wrong. sadness..' }
const methodError = { success: false, error: 'method not allowed' }
const userNotFoundMsg = 'user not found'
const menuNotFoundMsg = 'menu not found'
const userNotFound = { success: false, error: userNotFoundMsg }
const menuNotFound = { success: false, error: menuNotFoundMsg }
const userUndefined = { success: false, error: 'user undefined' }
const menuUndefined = { success: false, error: 'menu undefined' }
const aliasUndefined = { success: false, error: 'alias undefined' }
const userAlreadyExist = { success: false, error: 'user is already exist' }
const unableToSaveUser = { success: false, error: "unable to save that user, i'm so sorry.." }
const unableToCreate = { success: false, error: 'unable to create menu. sadness..' }
const unableToDelete = { success: false, error: 'unable to delete menu. sadness..' }
const unableToUpdate = { success: false, error: "unable to update menu. i'm so sorry.." }
const ok = { success: true, data: 'ok' }
const beforeTenSecondsTimeout = { success: true, data: 'time is out. try again' }

module.exports = {
  smthWrong,
  methodError,
  userNotFound,
  menuNotFound,
  userNotFoundMsg,
  menuNotFoundMsg,
  userUndefined,
  menuUndefined,
  aliasUndefined,
  userAlreadyExist,
  unableToSaveUser,
  unableToCreate,
  unableToDelete,
  unableToUpdate,
  ok,
  beforeTenSecondsTimeout,
}
