import React, { useState, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import Skeleton from 'react-loading-skeleton'
import qr from 'qr-image'
import JSZip from 'jszip'
import { getIndicator, getUser, deleteMenu } from '../helpers'
import { RootGetterContext, RootSetterContext, ManagerSetterContext } from '../context/userContext'
import MenuLanguageBlock from './MenuLanguageBlock'
import { fullList as availableLanguages } from '../data/langList'
import { ntfErrorWithLink } from '../helpers/notifications'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'
import DefaultLogo from '../img/DefaultLogo'

const { REACT_APP_CLOUDINARY_LOGO_PATH } = process.env

const zip = new JSZip()
const { IconCloneMenu, IconPen, IconRemoveItem } = icons

const TheIndicator = ({ events }) => {
  const { t, i18n } = useTranslation()
  const [itemIndicator, setItemIndicator] = useState(null)
  let timeout = null

  const autoUpdateIndicator = async () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setItemIndicator(getIndicator(events, i18n.language, t))
    timeout = setTimeout(async () => {
      autoUpdateIndicator()
    }, process.env.REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
  }

  useEffect(() => {
    if (events) {
      autoUpdateIndicator()
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [events, i18n.language])

  return (
    <div className='updated-at'>
      {itemIndicator ? (
        <>
          {itemIndicator?.map((item, i) => (
            <h4 key={i}>{item}</h4>
          ))}
        </>
      ) : (
        <h4>
          <Skeleton />
        </h4>
      )}
    </div>
  )
}

const MenuItem = ({ history, data }) => {
  const { token } = useContext(RootGetterContext)
  const { setShowRemoveModal, isLoadingGlobal, setIsLoadingGlobal } = useContext(RootSetterContext)
  const { setUserObject, setShowAliasOnlyModal } = useContext(ManagerSetterContext)
  const { user } = useAuth0()
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const [isOpen, setIsOpen] = useState(false)

  const { id, logo, events, translations, alias, defaultLanguage } = data ?? {}
  const url = alias && `https://${alias}.gimme.menu`

  const handleRedirect = () => {
    history.push(`/my/${id}`)
  }

  const handleCloneMenu = async () => {
    if (isLoadingGlobal) return
    setShowAliasOnlyModal(id)
  }

  const handleDeleteMenu = async () => {
    if (isLoadingGlobal) return
    setIsLoadingGlobal(true)
    try {
      await deleteMenu(token, user.sub, id)
      const userData = await getUser(token, user.sub)
      if (userData.success) {
        setUserObject(userData.data)
      } else {
        ntfErrorWithLink({
          title: t('MESSAGE_ERROR_TITLE'),
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
      }
      return userData
    } catch (e) {
      console.error(`handleDeleteMenu error:\n${e.toString()}`)
      ntfErrorWithLink({
        title: t('MESSAGE_ERROR_TITLE'),
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  const handleSaveQrZip = async () => {
    let filenamesArray = []
    for (let i = 0; i < translations.length; i++) {
      const urlToEncode =
        translations[i].lang !== defaultLanguage ? `${url}?lang=${translations[i].lang}` : url

      const qrcodePng = qr.image(urlToEncode, {
        type: 'png',
        size: 10,
        margin: 1,
      })
      const svgString = qr.imageSync(urlToEncode, {
        type: 'svg',
        size: 10,
        margin: 1,
      })
      const insertIndex = svgString.indexOf('><')
      const svgFilled = svgString
        .slice(0, insertIndex + 1)
        .concat(
          '<rect x="0" y="0" width="310" height="310" style="fill:#ffffff"/>',
          svgString.slice(insertIndex + 1)
        )
      // eslint-disable-next-line
      const svgBlob = new Blob([svgFilled], {
        type: 'image/svg+xml;charset=utf-8',
      })

      let svgName
      availableLanguages.forEach((lang) => {
        if (translations[i].lang === lang.value) {
          svgName = translations[i].lang === defaultLanguage ? `${lang.name}(default)` : lang.name
        }
      })
      zip.file(`menu_${svgName}.svg`, svgBlob)
      zip.file(`menu_${svgName}.png`, qrcodePng)
      filenamesArray.push(`menu_${svgName}.svg`, `menu_${svgName}.png`)
    }

    const zipResult = await zip.generateAsync({ type: 'blob' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipResult)
    a.setAttribute('download', 'menu-qr-codes.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    filenamesArray.forEach((item) => zip.remove(item))
  }

  const getEditMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={() => handleRedirect()}>
          <IconPen color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_EDIT')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getCloneMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={handleCloneMenu}>
          <IconCloneMenu color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_CLONE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getDeleteMenuButton = () => (
    <>
      {data ? (
        <div
          className='v2-button sm'
          onClick={() => {
            setShowRemoveModal({
              show: true,
              name: alias,
              kind: t('CON_MODAL_DELETE_MENU'),
              cbName: handleDeleteMenu,
              cbArgs: [],
            })
          }}
        >
          <IconRemoveItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_DELETE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getLogo = () => (
    <div className='logo'>
      {data ? (
        <>
          {logo ? (
            <img src={`${REACT_APP_CLOUDINARY_LOGO_PATH}${logo}`} alt='logo' />
          ) : (
            <DefaultLogo
              width={isDesktop ? '300px' : '100px'}
              height={isDesktop ? '300px' : '100px'}
            />
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  )

  const getTranslationsList = () => {
    return (
      <>
        {data
          ? translations.length > 1 && (
              <div
                aria-expanded={isOpen}
                className={`translations${isOpen ? ' open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className='translations-title'>
                  <h4>{t('MANAGER_MENU_ITEM_DROPDOWN_TEXT')}</h4>
                </div>
                {translations
                  .filter((item) => item.lang !== defaultLanguage)
                  .map((translation, t) => (
                    <MenuLanguageBlock
                      key={t}
                      menuId={id}
                      langName={translation.lang}
                      isPub={translation.isPub}
                      url={`${url}?lang=${translation.lang}`}
                      handleSaveQrZip={handleSaveQrZip}
                    />
                  ))}
              </div>
            )
          : null}
      </>
    )
  }

  const getMobileItem = () => (
    <div className='content-inner'>
      <div className='default-content'>
        <div className='content'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          {getEditMenuButton()}
        </div>
        {getLogo()}
      </div>
      <MenuLanguageBlock
        menuId={id}
        langName={defaultLanguage}
        isPub={!!events?.publishedAt}
        url={url}
        handleSaveQrZip={handleSaveQrZip}
      />
      {getTranslationsList()}
      <div className='delete-clone-btns'>
        {getDeleteMenuButton()}
        {getCloneMenuButton()}
      </div>
      <TheIndicator events={events} />
    </div>
  )

  const getDesktopItem = () => (
    <>
      <div className='content-block'>
        <div className='name-and-edit'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          <div className='button-block'>
            {getEditMenuButton()}
            {getCloneMenuButton()}
            {getDeleteMenuButton()}
          </div>
        </div>
        <MenuLanguageBlock
          menuId={id}
          langName={defaultLanguage}
          isPub={!!events?.publishedAt}
          url={url}
          handleSaveQrZip={handleSaveQrZip}
        />
        {getTranslationsList()}
      </div>
      <div className='logo-block'>
        <TheIndicator events={events} />
        {getLogo()}
      </div>
    </>
  )

  return (
    <div aria-expanded={isOpen} className='menu-item'>
      {isDesktop ? getDesktopItem() : getMobileItem()}
    </div>
  )
}

export default withRouter(MenuItem)
